import {GraveInfo, Item} from "./type";
import grave1 from "./images/grave_1.png";
import grave8 from "./images/grave_8.png";
import grave7 from "./images/grave_7.png";
import grave10 from "./images/grave_10.png";
import grave11 from "./images/grave_11.png";
import grave5 from "./images/grave_5.png";
import grave4 from "./images/grave_4.png";
import grave12 from "./images/grave_12.png";
import grave3 from "./images/grave_3.png";
import grave6 from "./images/grave_6.png";
import grave9 from "./images/grave_9.png";
import grave14 from "./images/grave_14.png";
import grave2 from "./images/grave_2.png";


const dyzItems: Item[] = [
    {name: "屠氏(文华之妻)", xAxis: 80, yAxis: 40},
    {name: "文荣和妻张氏", together: true, xAxis: 200, yAxis: 40},
    {name: "张梅芳", xAxis: 90, yAxis: 100},
    {name: "文思明", xAxis: 200, yAxis: 100}
]

const dwzItems: Item[] = [
    {name: "文殿元", xAxis: 270, yAxis: 50},
    {name: "文殿元妻", xAxis: 170, yAxis: 50},
    {name: "文玉章", xAxis: 130, yAxis: 100, together: true},
    {name: "文有章", xAxis: 190, yAxis: 100, together: true},
    {name: "文焕章", xAxis: 250, yAxis: 100, together: true},
    {name: "文承章", xAxis: 310, yAxis: 100, together: true},
    {name: "？", xAxis: 150, yAxis: 150},
    {name: "？", xAxis: 200, yAxis: 150, together: true},
    {name: "？", xAxis: 250, yAxis: 150},
    {name: "？", xAxis: 300, yAxis: 150},
    {name: "？", xAxis: 360, yAxis: 200},
    {name: "？", xAxis: 80, yAxis: 200},
    {name: "？", xAxis: 30, yAxis: 150},
]


const dwzGrave: GraveInfo = {
    imgUrl: grave14,
    desc: "一队大湾子墓群",
    items: dwzItems,
    useBigCanvas: true
}

const dyzGrave: GraveInfo = {
    imgUrl: grave2,
    desc: "大园子墓群",
    items: dyzItems
}
export const graveInfos: { [key: string]: GraveInfo } = {
    "1_1": {
        imgUrl: grave1,
        desc: "祖先【陈氏】之墓",
        items: [
            {name: "祖先【陈氏】", xAxis: 150, yAxis: 80}
        ]
    },
    "2_1": dwzGrave,
    "3_1": dwzGrave,
    "3_2": dwzGrave,
    "3_3": dwzGrave,
    "3_4": dwzGrave,
    "4_3": {
        imgUrl: grave8,
        desc: "祖先【文华】之墓",
        items: [
            {name: "文华", xAxis: 150, yAxis: 80}
        ]
    },
    "4_2": dyzGrave,
    "4_2_7": dyzGrave,
    "4_3_7": dyzGrave,
    "5_1_7": {
        imgUrl: grave7,
        desc: "祖先【王玉兰】之墓",
        items: [
            {name: "王玉兰", xAxis: 150, yAxis: 80}
        ]
    },
    "5_1": {
        imgUrl: grave10,
        desc: "祖先【文思存】之墓",
        items: [
            {name: "文思存", xAxis: 150, yAxis: 80}
        ]
    },
    "5_2": dyzGrave,
    "5_2_7": dyzGrave,
    "5_3": {
        imgUrl: grave11,
        desc: "祖先【文思恭】之墓",
        items: [
            {name: "文思恭", xAxis: 150, yAxis: 80, extra: "13岁故"}
        ]
    },
    "5_5": {
        imgUrl: grave5,
        desc: "祖先【文思忠】之墓",
        items: [
            {name: "文思忠", xAxis: 150, yAxis: 80}
        ]
    },
    "5_5_7": {
        imgUrl: grave4,
        desc: "祖先【张桂芳】之墓",
        items: [
            {name: "张桂芳", xAxis: 150, yAxis: 80, extra: "文思忠之妻"}
        ]
    },
    "5_6": {
        imgUrl: grave12,
        desc: "祖先【文镜堂】之墓",
        items: [
            {name: "文镜堂", xAxis: 150, yAxis: 80}
        ]
    },
    "5_6_7": {
        imgUrl: grave3,
        desc: "祖先【吴桂花】之墓",
        items: [
            {name: "吴桂花", xAxis: 150, yAxis: 80, extra: "文镜堂之妻"}
        ]
    },
    "6_6": {
        imgUrl: grave6,
        desc: "祖先【文国信】之墓",
        items: [
            {name: "文国信", xAxis: 150, yAxis: 80}
        ]
    },
    "6_7": {
        imgUrl: grave9,
        desc: "祖先【文国胜】之墓",
        items: [
            {name: "文国胜", xAxis: 150, yAxis: 80, extra: "27岁故"}
        ]
    }
}