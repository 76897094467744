export interface GraveInfo{
    imgUrl: string
    desc?: string
    items: Item[]
    useBigCanvas?: boolean
}

export type Gender = 'male' | 'female'

export interface Item{
    name: string
    rank?: string
    together?: boolean
    father?: string
    mother?: string
    memorialDay?: string
    children?: string
    extra?: string
    wife?: string
    xAxis: number
    yAxis: number
}

export const  DEFAULT_TITLE = "定西市巉口镇文氏族谱信息"

export interface TreeData {
    name: string,
    children?:[TreeData],
    spouse?: boolean,
    gender: Gender
}