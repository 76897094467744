import React, {FC, useEffect, useMemo} from 'react';
import './App.css';
import {DownOutlined, UserOutlined} from '@ant-design/icons';
import {message, Space, Tree, TreeDataNode, TreeProps, Typography} from "antd";
import {useNavigate} from "react-router";

import {graveInfos} from "./database";
import {useSearchParams} from "react-router-dom";
import {TreeData} from "./type";
import treeData from "./data.json";

const {Text,Paragraph} = Typography;

const femaleIcon = () => <UserOutlined style={{color: "hotpink"}}/>
const maleIcon = () => <UserOutlined style={{color: "darkblue"}}/>

const App: FC = () => {
    const totalCount = 104
    useEffect(() => {
        document.title = "定西市巉口镇文氏族谱信息"
    }, [])
    const [api, contextHolder] = message.useMessage();

    const showWarning  = () => {
        api.open({
            type: 'warning',
            content: '暂未收录更多信息,等待后续补充',
        });
    };
    const dataList = treeData as unknown as TreeData[]
    const showData = useMemo(()=>{
        const levelMap: { [key: number]: number } = {}
        return cal(dataList, 1, levelMap);
    },[])
    console.log("showData==", showData)
    const navigate = useNavigate();
    // console.log('defaultData:', defaultData)
    let [searchParams] = useSearchParams();
    const frm = searchParams.get('frm')
    const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        const key = selectedKeys[0] as string
        if (graveInfos[key]) {
            navigate(`/u/${key}`)
        } else {
            showWarning()
        }
    };

    function cal(dataList: TreeData[], level: number, levelMap: { [key: number]: number }): TreeDataNode[] {
        return dataList.map((item: TreeData)=>{
            let defaultChildren
            if (item.children) {
                defaultChildren = cal(item.children, level + 1, levelMap)
            }
            if (isNaN(levelMap[level])) {
                levelMap[level] = 1
            } else if (!item.spouse){
                levelMap[level] = levelMap[level] + 1
            }
            let keyVal = `${level}_${levelMap[level]}`
            //给个特殊编码
            if(item.spouse){
                keyVal = `${keyVal}_7`
            }
            return {
                title: item.name,
                key: keyVal,
                children: defaultChildren,
                icon: item.gender === 'female'? femaleIcon(): maleIcon()
            }
        })
    }


    return (
        <div style={{marginLeft:"10px"}}>
            {contextHolder}
            <Space direction="vertical" className="container">
                <Text strong className="txt">甘肃省定西市巉口镇文氏家族信息树({totalCount}人)</Text>
                <Paragraph italic className="txt" mark={true}
                           style={{width:"300px", wordWrap: 'break-word', whiteSpace: 'normal'}}>
                    “家谱之中，传承为重。代代相传，血脉相承，家道光耀，德行相传”</Paragraph>
                <Text strong className="txt">（*点击已故祖先人名可以跳转到具体墓地所在地）</Text>
                <Tree
                    showLine
                    switcherIcon={<DownOutlined/>}
                    defaultExpandedKeys={[frm || '2_1']}
                    treeData={showData}
                    showIcon={true}
                    onSelect={onSelect}
                />
            </Space>
        </div>
    )
}
export default App;
