import {FC, useEffect, useRef, useState} from "react";
import {Flex, Image, Modal} from "antd";
import {useLoaderData, useParams} from "react-router";
import {DEFAULT_TITLE, GraveInfo, Item} from "./type";
import {Link} from "react-router-dom";
import {graveInfos} from "./database";
export async function loader({params}: { params: any }) {
    return graveInfos[params.id];
}

const Main: FC = () => {
    const params = useParams()
    const graveInfo = useLoaderData() as GraveInfo
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentGrave, setCurrentGrave] = useState<Item | null>(null)
    const canvasRef = useRef(null)
    useEffect(() => {
        document.title = graveInfo.desc || DEFAULT_TITLE
        const canvas = canvasRef.current
        // @ts-ignore
        const context = canvas.getContext('2d')
        context.fillStyle = '#99cccc';
        context.fillRect(0, 0, 400, 400);
        if (graveInfo.useBigCanvas) {
            drawLine(context)
        }
        graveInfo.items.forEach((value) => {
            if (value.together) {
                context.fillStyle = 'blue';
            } else {
                context.fillStyle = 'gray';
            }
            context.beginPath();
            context.fillText(`${value.name}之墓`, value.xAxis - 25, value.yAxis - 15)
            context.arc(value.xAxis, value.yAxis, 8, 0, 2 * Math.PI);
            context.fill();
        })
        // @ts-ignore
        canvas.addEventListener('click', function (event) {
            // @ts-ignore
            const x = event.clientX - canvas.getBoundingClientRect().left;
            // @ts-ignore
            const y = event.clientY - canvas.getBoundingClientRect().top;

            graveInfo.items.forEach(function (grave: Item) {
                if (Math.abs(x - grave.xAxis) < 10 && Math.abs(y - grave.yAxis) < 10) {
                    setCurrentGrave(grave)
                    showModal()
                }
            });
        });
        //Our draw come here
    }, [graveInfo.desc, graveInfo.items, graveInfo.useBigCanvas])
    const drawLine = (context: any) => {
        //竖线1
        context.setLineDash([10, 5]);
        //划线
        context.beginPath();
        // 移动到起始点 (x, y)
        context.moveTo(100, 0);
        // 绘制到结束点 (x, y)
        context.lineTo(100, 300);
        // 设置线条样式（可选）
        context.strokeStyle = 'gray';
        context.lineWidth = 1; // 设置线条宽度
        // 绘制路径上的线条
        context.stroke();

        //竖线2
        context.moveTo(340, 0);
        context.lineTo(340, 300);
        context.stroke();

        //横线
        context.moveTo(0, 170);
        context.lineTo(400, 170);
        context.stroke();
    }


    const showModal = () => {
        setIsModalOpen(true);
    }
    const handleOk = () => {
        setIsModalOpen(false);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <Flex gap="middle" vertical align="center" justify="center" style={{marginTop: "20px"}}>
            <span>{graveInfo.desc}&nbsp;&nbsp;&nbsp;大致地图方位(点击图片可放大)：</span>
            <Image src={graveInfo.imgUrl} height="260px"></Image>
            <p>下图小点代表墓地分布，点击可以查看更多信息（蓝色为双葬墓，灰色为单葬墓）：</p>
            <canvas ref={canvasRef} className="iCanvas" width={graveInfo.useBigCanvas ? "400" : "300"}
                    height={graveInfo.useBigCanvas ? "300" : "150"}></canvas>
            <Modal title="当前选中信息显示" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>墓主：{currentGrave?.name}</p>
                {currentGrave?.memorialDay && <p>忌日：{currentGrave?.memorialDay}</p>}
                {currentGrave?.rank && <p>排行：{currentGrave?.rank}</p>}
                {currentGrave?.wife && <p>妻子：{currentGrave?.wife}</p>}
                {currentGrave?.extra && <p>补充信息：{currentGrave?.extra}</p>}
            </Modal>
            <Link to={"/?frm=" + params.id}>回到首页</Link>
        </Flex>
    )
}
export default Main